<template>
  <fragment>
    <article class="container-fluid">
      <h1 class="main-title">{{ $t("alerts.title") }}</h1>
      <alerts-list />
    </article>
  </fragment>
</template>

<script>
import AlertsList from "@/components/alerts/AlertsList.vue";

export default {
  name: "AlertsView",
  components: { AlertsList },
};
</script>

<style lang="scss" scoped>

</style>